@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/* CSS Document */
/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	first
--------------------------------------------------------------------*/
.first {
	margin: 0;
	padding: 0;
	position: relative;
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 50% top;
	overflow: hidden;
	@include media(pc_less) {
		background: transparent;
		position: relative;
		width: 100%;
		&::before {
			background-position: 50% top;
			background-repeat: no-repeat;
			background-size: contain;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: "";
			z-index: -1;
		}
		&::after {
			content: "";
			display: block;
			padding-top: 75%;
			z-index: -1;
		}
	}
	@include media(sp_less) {
		&::after {
			padding-top: 133%;
		}
	}
	&::before {
		@include media(pc_less) {
			background-image: url(../img/bg_01_tb.jpg);
		}
		@include media(sp_less) {
			background-image: url(../img/bg_01_sp.jpg);
		}
	}
	&-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		background: transparent;
		&.blur {
			@include media(pc_less) {
				backdrop-filter: blur(8px);
			}
		}
	}
	.l-cont {
		position: relative;
		margin: 0 auto;
		height: 100%;
		z-index: 1;
		@include media(pc_less) {
			position: absolute;
			margin: 0 auto;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
}

.first {
	@include media(pc) {
		height: 100vh;
	}
}

.fv-wrap {
	width: 100%;
	height: 100%;
	position: relative;
}

/* scroll */
.scroll {
	&__wrap {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}
.scrolldown {
	font-weight: 400;
	a {
		position: absolute;
		color: $white;
		@include dec-none;
		@include transition;
		bottom: 20px;
		left: 50%;
		z-index: 2;
		display: inline-block;
		transform: translateX(-50%);
		text-decoration: none;
		@include f-em(20);
		&:hover {
			color: $main_c;
			span {
				border-color: $main_c;
				@include transition;
				&::before {
					background-color: $main_c;
				}
			}
		}
		@include media(pc) {
			padding-top: 60px;
			span {
				position: absolute;
				top: 0;
				left: 50%;
				width: 30px;
				height: 50px;
				margin-left: -15px;
				border: 2px solid $white;
				border-radius: 50px;
				box-sizing: border-box;
				&::before {
					position: absolute;
					top: 10px;
					left: 50%;
					content: "";
					width: 6px;
					height: 6px;
					margin-left: -3px;
					background-color: $white;
					border-radius: 100%;
					animation: ball 2s infinite;
				}
			}
		}
		@include media(pc_less) {
			padding-top: 60px;
			span {
				position: absolute;
				top: 0;
				left: 50%;
				width: 24px;
				height: 24px;
				margin-left: -12px;
				border-left: 1px solid $white;
				border-bottom: 1px solid $white;
				transform: rotate(-45deg);
				animation: arrow 1.5s infinite;
			}
		}
	}
}


@keyframes ball {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	80% {
		transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes arrow {
	0% {
		transform: rotate(-45deg) translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: rotate(-45deg) translate(-20px, 20px);
		opacity: 0;
	}
}

/*--------------------------------------------------------------------/
	content
--------------------------------------------------------------------*/

.cont-wrap {
	padding-top: 4rem;
	@include media(tb) {
		padding-top: calc(4rem + 50 * (100vw - #{$tb}) / #{$pc_size - $tb_size});
	}
}
.txt-intro {
	text-align: left;
	margin-bottom: 2rem;
	@include f-s_sp(1, 2);
	@include media(pc_s) {
		text-align: center;
		margin-bottom: 3.5rem;
	}
}
/* top-cont01 */
.top-cont01 {
	background: $white;
	z-index: 1;
}


/* top-cont02 */
.top-cont02 {
	color: $white;
	padding: 0;
	width: 100%;
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 50% top;
	@include media(tb_less) {
		background-size: cover;
		background-position: 50% 0;
	}
	@include media(pc_less) {
		background-image: url(../img/bg_02_pc.jpg);
	}
	@include media(tb_less) {
		background-image: url(../img/bg_02_sp.jpg);
	}
	& .category-list {
		@include media(pc) {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	.free-content {
		@include media(pc) {
			padding-bottom: 60px;
		}
	}
}
.top-cont01 .btnselect .c-box__txt-01,
.top-cont02 .btnselect .mCSB_container {
	padding-bottom: 6rem;
	position: relative;
}
.top-cont01 .btnselect .c-box__txt-01,
.top-cont02 .btnselect .mCSB_container {
	padding-bottom: 6rem;
	position: relative;
}

.works-list__inner {
	padding-bottom: 3rem;
}

/* top-cont03 */
.top-cont03 {
	background: $white;
	z-index: 1;
	& .c-box__tit {
		margin-bottom: 1.25rem;
		@include media(pc_s) {
			margin-bottom: 2rem;
		}
	}
	& .l-box {
		@include media(pc_s) {
			@include m-a;
			max-width: $tb;
		}
	}
}

.company-cont {
	background: $sub_bgcl;
	padding: 2rem 4%;
	border-radius: 5px;
}

#recruit{
	padding-top: 8rem;
}
/* top-cont04 */
.top-cont04 {
	&__info {
		background: $sub_bgcl;
	}
	&__news {
		color: $txt_c;
		background: $sub_bgcl;
		.feed-box__wrap {
			@include media(pc_s) {
				max-width: 900px;
			}
		}
	}
	.cont__tit {
		color: darken($main_c, 10%);
	}
}

.info-box01 {
	.frame {
		background: $white;
	}
	.tax__icon {
		margin-left: 0;
		color: $white;
		@include fz(13);
	}
	&__tit {
		@include f-em(16);
		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid $main_c;
	}
	&__date {
		font-family: "Oswald", sans-serif !important;
		font-weight: 400;
	}
	&__txt {
		margin-bottom: 2rem;
	}
	.btn--arrow {
		background-color: $main_c;
	}
	a {
		&:hover {
			.btn--arrow {
				background-color: $sub_c;
			}
		}
	}
}

[class*="top-cont04"] {
	padding-top: 7rem;
	padding-bottom: 7rem;
	@include media(pc_s) {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}
}

%pic-common {
	figure {
		background: transparent !important;
		margin-bottom: 2rem !important;
		@include media(sp_less) {
			@include m-a;
			max-width: 240px;
		}
	}
	figcaption {
		margin-top: 1rem;
	}
	@include media(pc_s) {
		@include flex-between;
		.pic-img {
			flex-basis: 31%;
		}
		.txt {
			flex-basis: 65%;
		}
	}
}

.pic {
	&-left {
		@extend %pic-common;
		@include media(pc_s) {
			.pic-img {
				margin-right: 4%;
			}
		}
	}
	&-right {
		@extend %pic-common;
		@include media(pc_s) {
			flex-direction: row-reverse;
			.pic-img {
				background: transparent;
			}
		}
	}
}

/* map  */
.map {
	border-radius: 5px;
	position: relative;
	padding: 0;
	height: 0;
	overflow: hidden;
	background-color: $white;
	padding-bottom: 40%;
	@include media(pc_s) {
		padding-bottom: 480px;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		vertical-align: bottom;
	}
}

/* map  */

#access{
	padding-top: 8rem;
	& .c-box__tit{
		
		@include media(pc_s) {
			font-size: 20px;
		}
	}
}
.map {
	border-radius: 5px;
	position: relative;
	padding: 0;
	height: 0;
	overflow: hidden;
	background-color: $white;
	padding-bottom: 40%;
	@include media(pc_s) {
		padding-bottom: 480px;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		vertical-align: bottom;
	}
}


.sub-box {
	max-width: 90%;
	
	margin: 40px auto;
	@include media(pc_s) {
		max-width: 80%;

	}
	@include media(sp_less) {
		max-width: 100%;
	
	}
  }
  
  .map {
	position: relative;
	height: 0;
	padding-bottom: 40%;
	overflow: hidden;
	// margin-bottom: 2rem;
  }
  
  .map iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }

  .flex-maps{
	@include media(pc_l) {
		width: 90%;
	
	}
	width: 90%;
    margin: 0 auto;
	// @include media(pc) {
	// 	flex-direction: row;
	// 	display: flex!important;
	// justify-content: space-around;
	// }

}
// .txt-wrap{
// 	padding: 2rem 1rem;
// }
.flex-item{
	width: 100%;

	margin-bottom: 5rem;
	@include media(pc_s) {
		width: 70%;	
		margin-bottom: 0rem;
		max-width: 900px;
	}
@include media(pc) {
		width:80%;
	}
}



/* top-cont05  */
.top-cont05 {
	@extend .top-cont02;
	@include media(pc_less) {
		background-image: url(../img/bg_03_pc.jpg);
	}
	@include media(tb_less) {
		background-image: url(../img/bg_03_sp.jpg);
	}
	.l-cont {
		position: relative;
		z-index: 1;
	}
	.tel-link {
		a {
			color: $white;
		}
	}
}
.contact__tel {
	text-align: center;
	margin-bottom: 2rem;
	@include f-s_sp(2.5, 15);
	@include media(pc) {
		@include fz(40);
	}
	a {
		color: $white !important;
		@include dec-none;
	}
	.tel-link {
		a {
			vertical-align: bottom;
		}
	}
}
/* お問い合わせコンテンツ */
.top-cont05 .btn__wrap {
	max-width: 420px;
}
.top-cont05 .black-bg{
opacity: 0.5;
background: #414141;
}
.contact__tel {
	text-align: center;
	@include f-family(font02);
	font-weight: 400;
	margin-bottom: 1rem;
}

@import "_component/feed"; //feed
@import "_component/swiper";

@media all and (-ms-high-contrast: none) {
	*::-ms-backdrop,
	#global .global__items > li a {
		height: 100%;
	}
}
@media screen and (orientation: landscape) {
	.first::before {
		background-size: cover;
		-webkit-background-size: cover;
	}
}
@media print {
	header {
		display: none;
	}

	nav#global {
		display: none;
	}

	footer {
		display: none;
	}
}

/* top-qa */
.top-qa {
	background-color: #f7f7f7;
	.l-box {
		@include media(pc_s) {
			@include m-a;
			max-width: $tb;
		}
	}
}

.qa-dl {
	counter-reset: qa-num;
	width: 100%;
	dt {
		position: relative;
		padding-left: calc(3.5rem + 2vw);
		margin-bottom: 1.5rem;
		@include f-s_sp(1.6, 2);
		@include f-w(500);
		color: $b-gray;
		@include line-h(1.4);
		@include media(pc) {
			padding-left: 5rem;
			@include fz(18);
		}
		&:not(:first-child) {
			margin-top: 3rem;
		}
		&::before {
			color: $main_c;
			counter-increment: qa-num;
			position: absolute;
			// font-family: "Oswald", sans-serif !important;
			top: -0.25em;
			left: 0;
			@include f-w(700);
			content: "Q." counter(qa-num);
			font-size: 1.4em;
		}
	}
	dd {
		padding-left: calc(3.5rem + 2vw);
		@include media(pc) {
			padding-left: 5rem;
		}
		&:not(:last-child) {
			padding-bottom: 3rem;
			border-bottom: 1px dotted $d-gray;
		}
	}
}

/* 20210407 追記 タブレット時挨拶画像サイズ調整 */
.top-cont03 .pic-img {
	@include media(tb) {
		text-align: center;
	}
}

.top-cont03 .ofi-contain {
	@include media(tb) {
		max-width: 300px;
		margin: 0 auto;
	}
}

.top-cont03 .greeting__name {
	@include media(tb) {
		text-align: center;
	}
}


.top-cont05{
	& .btn__wrap a{
			padding: 1.6rem 0;
			font-size: 1.7rem;
		}
	
	}
	

	#map{
		padding-top: 8rem;
	}

.top-cont03	.mb-ll{
max-width: 800px;
margin: auto;
margin-bottom: 8rem;
}